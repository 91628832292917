// auth
export const USER_SET_DEFAULTS = 'USER_SET_DEFAULTS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL_PASS = 'UPDATE_PROFILE_FAIL_PASS';
export const SET_UPDATE_USER_SUCCESS = 'SET_UPDATE_USER_SUCCESS';
export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';
export const SELECT_COMPANY_REQUEST = 'SELECT_COMPANY_REQUEST';
export const SELECT_COMPANY_SUCCESS = 'SELECT_COMPANY_SUCCESS';
export const SELECT_COMPANY_PAGE_LOADED = 'SELECT_COMPANY_PAGE_LOADED';

export const USER_BACK_TO_OLD = 'USER_BACK_TO_OLD';

export const GET_FONTS_REQUEST = 'GET_FONTS_REQUEST';
export const GET_FONTS_SUCCESS = 'GET_FONTS_SUCCESS';
export const SET_SPLIT_FEATURES = 'SET_SPLIT_FEATURES';
// end auth

// menu
export const MENU_TOGGLE = 'MENU_TOGGLE';
export const MENU_SET_ACTIVE = 'MENU_SET_ACTIVE';
// end menu

// /general
export const CALENDAR_CHANGE = 'CALENDAR_CHANGE';
export const PAGE_CHANGE = 'PAGE_CHANGE';
export const EDIT_TITLE_SUCCESS = 'EDIT_TITLE_SUCCESS';
export const EDIT_TITLE_FAIL = 'EDIT_TITLE_FAIL';
export const EDIT_TITLE_TOGGLE = 'EDIT_TITLE_TOGGLE';
export const TOGGLE_STATUS_SWITCH = 'TOGGLE_STATUS_SWITCH';
export const SET_STATUS_SWITCH = 'SET_STATUS_SWITCH';
// end general

// creatives
export const SELECT_CREATIVE = 'SELECT_CREATIVE';
export const SET_CREATIVE = 'SET_CREATIVE';
export const RECEIVE_CREATIVES = 'RECEIVE_CREATIVES';
export const GET_CREATIVES_REQUEST = 'GET_CREATIVES_REQUEST';
export const GET_CREATIVE_REQUEST = 'GET_CREATIVE_REQUEST';
export const GET_CREATIVES_SUCCESS = 'GET_CREATIVES_SUCCESS';
export const TOGGLE_CREATIVES_ITEM = 'TOGGLE_CREATIVES_ITEM';
export const TOGGLE_CREATIVES_ALL = 'TOGGLE_CREATIVES_ALL';
export const DESELECT_CREATIVES_ALL = 'DESELECT_CREATIVES_ALL';
export const SET_CREATIVES_DEFAULTS = 'SET_CREATIVES_DEFAULTS';
export const GET_CREATIVE_SUCCESS = 'GET_CREATIVE_SUCCESS';
export const GENERATE_VIDEOS_SUCCESS = 'GENERATE_VIDEOS_SUCCESS';
export const CREATE_CREATIVE_SUCCESS = 'CREATE_CREATIVE_SUCCESS';
export const CREATE_CREATIVE_FAIL = 'CREATE_CREATIVE_FAIL';
export const NEW_CREATIVE_REQUEST = 'NEW_CREATIVE_REQUEST';
export const DELETE_CREATIVES_IN_PROGRESS = 'DELETE_CREATIVES_IN_PROGRESS';
export const DELETE_CREATIVES_SUCCESS = 'DELETE_CREATIVES_SUCCESS';
export const DELETE_CREATIVES_FAIL = 'DELETE_CREATIVES_FAIL';
export const DUPLICATE_CREATIVES_IN_PROGRESS = 'DUPLICATE_CREATIVES_IN_PROGRESS';
export const DUPLICATE_CREATIVES_SUCCESS = 'DUPLICATE_CREATIVES_SUCCESS';
export const DUPLICATE_CREATIVES_FAIL = 'DUPLICATE_CREATIVES_FAIL';
export const IMPORT_CREATIVES_GHOST_CARDS = 'IMPORT_CREATIVES_GHOST_CARDS';
export const SET_CREATIVE_VIDEOS = 'SET_CREATIVE_VIDEOS';
export const CREATIVES_SET_REDIRECT = 'CREATIVES_SET_REDIRECT';
export const EDIT_CREATIVE_NAME = 'EDIT_CREATIVE_NAME';
export const UPDATE_CREATIVE_VARIATIONS_COUNT = 'UPDATE_CREATIVE_VARIATIONS_COUNT';
export const RENAME_CREATIVE_IN_PROGRESS = 'RENAME_CREATIVE_IN_PROGRESS';
export const RENAME_CREATIVE_SUCCESS = 'RENAME_CREATIVE_SUCCESS';
export const RENAME_CREATIVE_FAILURE = 'RENAME_CREATIVE_FAILURE';
export const CREATIVES_CHANGE_SOME = 'CREATIVES_CHANGE_SOME';
export const PENDING_REQUESTS = 'PENDING_REQUESTS';
// end creatives

// creative Videos

export const CREATIVE_VIDEO_NAME_EDITABLE_TOGGLE = 'CREATIVE_VIDEO_NAME_EDITABLE_TOGGLE';
export const UPDATE_CREATIVE_VIDEO_REQUEST = 'UPDATE_CREATIVE_VIDEO_REQUEST';
export const UPDATE_CREATIVE_VIDEO_SUCCESS = 'UPDATE_CREATIVE_VIDEO_SUCCESS';
export const UPDATE_CREATIVE_VIDEO_FAIL = 'UPDATE_CREATIVE_VIDEO_FAIL';
export const GET_RENDERING_VIDEOS_COUNT_SUCCESS = 'GET_RENDERING_VIDEOS_COUNT_SUCCESS';
export const CREATIVE_VIDEOS_SELECT_VIDEO = 'CREATIVE_VIDEOS_SELECT_VIDEO';
export const GET_CREATIVE_VIDEOS_SUCCESS = 'GET_CREATIVE_VIDEOS_SUCCESS';
export const GET_CREATIVE_VIDEOS_REQUEST = 'GET_CREATIVE_VIDEOS_REQUEST';
export const GET_CREATIVE_VIDEO_VERSIONS_SUCCESS = 'GET_CREATIVE_VIDEO_VERSIONS_SUCCESS';
export const GET_CREATIVE_VIDEO_VERSIONS_REQUEST = 'GET_CREATIVE_VIDEO_VERSIONS_REQUEST';
export const CREATIVE_VIDEO_RENDITIONS_SUCCESS = 'CREATIVE_VIDEO_RENDITIONS_SUCCESS';
export const CREATIVE_VIDEO_RENDITIONS_REQUEST = 'CREATIVE_VIDEO_RENDITIONS_REQUEST';
export const CREATIVE_VIDEO_RENDITIONS_CLEAR = 'CREATIVE_VIDEO_RENDITIONS_CLEAR';
export const CREATIVE_VIDEOS_RESET = 'CREATIVE_VIDEOS_RESET';
export const GENERATE_UNMOUNT = 'GENERATE_UNMOUNT';
export const TOGGLE_CREATIVE_VIDEOS_ALL = 'TOGGLE_CREATIVE_VIDEOS_ALL';
export const DESELECT_CREATIVE_VIDEOS_ALL = 'DESELECT_CREATIVE_VIDEOS_ALL';
export const TOGGLE_CREATIVE_VIDEOS_ITEM = 'TOGGLE_CREATIVE_VIDEOS_ITEM';
export const CREATIVE_DELETE_MODAL_TOGGLE = 'CREATIVE_DELETE_MODAL_TOGGLE';
export const CREATIVE_DUPLICATE_MODAL_TOGGLE = 'CREATIVE_DUPLICATE_MODAL_TOGGLE';
export const CREATIVE_VIDEOS_DELETE_MODAL_TOGGLE = 'CREATIVE_VIDEOS_DELETE_MODAL_TOGGLE';
export const DELETE_CREATIVE_VIDEOS_SUCCESS = 'DELETE_CREATIVE_VIDEOS_SUCCESS';
export const DELETE_CREATIVE_VIDEOS_FAIL = 'DELETE_CREATIVE_VIDEOS_FAIL';
export const CREATIVE_VIDEOS_CHANGE_SOME = 'CREATIVE_VIDEOS_CHANGE_SOME';
export const SER_CREATIVE_VIDEO_SELECTED_DATA = 'SER_CREATIVE_VIDEO_SELECTED_DATA';
export const CREATIVE_VIDEOS_SET_VERSIONS = 'CREATIVE_VIDEOS_SET_VERSIONS';
export const UPLOAD_CREATIVE_VIDEOS_SUCCESS = 'UPLOAD_CREATIVE_VIDEOS_SUCCESS';
export const UPLOAD_CREATIVE_VIDEOS_FAIL = 'UPLOAD_CREATIVE_VIDEOS_FAIL';

// end creative Videos

// assets
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export const GET_ASSETS_REQUEST = 'GET_ASSETS_REQUEST';
export const SELECT_ASSET_REQUEST = 'SELECT_ASSET_REQUEST';
export const SELECT_ASSET_SUCCESS = 'SELECT_ASSET_SUCCESS';
export const GET_PATH_REQUEST = 'GET_PATH_REQUEST';
export const GET_PATH_SUCCESS = 'GET_PATH_SUCCESS';
export const CREATE_ASSET_REQUEST = 'CREATE_ASSET_REQUEST';
export const CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS';
export const TOGGLE_ASSETS_ITEM = 'TOGGLE_ASSETS_ITEM';
export const TOGGLE_ASSETS_ALL = 'TOGGLE_ASSETS_ALL';
export const RESET_ASSETS = 'RESET_ASSETS';
export const ASSETS_DELETE_MODAL_TOGGLE = 'ASSETS_DELETE_MODAL_TOGGLE';
export const DELETE_ASSETS_SUCCESS = 'DELETE_ASSETS_SUCCESS';
export const DELETE_ASSETS_FAIL = 'DELETE_ASSETS_FAIL';
export const DELETE_ASSETS_REQUEST = 'DELETE_ASSETS_REQUEST';
export const CHECK_ASSETS_REQUEST = 'CHECK_ASSETS_REQUEST';
export const CHECK_ASSETS_SUCCESS = 'CHECK_ASSETS_SUCCESS';
export const ASSETS_CHANGE_SOME = 'ASSETS_CHANGE_SOME';
export const ASSETS_SET_REDIRECT = 'ASSETS_SET_REDIRECT';
export const ASSETS_DESELECT_ALL = 'ASSETS_DESELECT_ALL';
export const RENAME_ASSET_IN_PROGRESS = 'RENAME_ASSET_IN_PROGRESS';
export const RENAME_ASSET_SUCCESS = 'RENAME_ASSET_SUCCESS';
export const RENAME_ASSET_FAILURE = 'RENAME_ASSET_FAILURE';
// end assets

// templates
export const INITIALIZE_TEMPLATES = 'INITIALIZE_TEMPLATES';
export const TEMPLATES_RESET_OWNERSHIP = 'TEMPLATES_RESET_OWNERSHIP';

export const GET_SYSTEM_TEMPLATES_REQUEST = 'GET_SYSTEM_TEMPLATES_REQUEST';
export const GET_SYSTEM_TEMPLATES_SUCCESS = 'GET_SYSTEM_TEMPLATES_SUCCESS';
export const GET_SYSTEM_TEMPLATES_FAIL = 'GET_SYSTEM_TEMPLATES_FAIL';

export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAIL = 'GET_TEMPLATES_FAIL';
export const SET_TEMPLATE = 'SET_TEMPLATE';

export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const TOGGLE_ALL_TEMPLATES = 'TOGGLE_ALL_TEMPLATES';
export const TOGGLE_TEMPLATE = 'TOGGLE_TEMPLATE';

export const ADD_TEMPLATE_REQUEST = 'ADD_TEMPLATE_REQUEST';
export const ADD_TEMPLATE_SUCCESS = 'ADD_TEMPLATE_SUCCESS';
export const ADD_TEMPLATE_FAIL = 'ADD_TEMPLATE_FAIL';

export const SET_RENAME_TEMPLATE_STATUS = 'SET_RENAME_TEMPLATE_STATUS';
export const UPDATE_TEMPLATES = 'UPDATE_TEMPLATES';
export const RESET_FETCHED_TEMPLATES = 'RESET_FETCHED_TEMPLATES';

export const DESELECT_TEMPLATE = 'DESELECT_TEMPLATE';

export const DELETE_TEMPLATES_IN_PROGRESS = 'DELETE_TEMPLATES_IN_PROGRESS';
export const DELETE_TEMPLATES_SUCCESS = 'DELETE_TEMPLATES_SUCCESS';
export const DELETE_TEMPLATES_FAIL = 'DELETE_TEMPLATES_FAIL';
export const TEMPLATES_CHANGE_SOME = 'TEMPLATES_CHANGE_SOME';
// end templates

// gallery
export const INITIALIZE_GALLERY = 'INITIALIZE_GALLERY';

export const GET_GALLERY_REQUEST = 'GET_GALLERY_REQUEST';
export const GET_GALLERY_SUCCESS = 'GET_GALLERY_SUCCESS';
export const GET_GALLERY_FAIL = 'GET_GALLERY_FAIL';
export const SET_GALLERY_ITEM = 'SET_GALLERY_ITEM';

export const SELECT_GALLERY_ITEM = 'SELECT_GALLERY_ITEM';
export const DESELECT_GALLERY_ITEM = 'DESELECT_GALLERY_ITEM';
export const RESET_FETCHED_GALLERY = 'RESET_FETCHED_GALLERY';
export const GALLERY_RESET_OWNERSHIP = 'GALLERY_RESET_OWNERSHIP';
// end gallery

export const FILE_DROPPED = 'FILE_DROPPED';

export const SET_LOADING_BAR_COUNT = 'SET_LOADING_BAR_COUNT';

// creative editor
export const SET_EDITOR_SHOT = 'SET_EDITOR_SHOT';
export const SET_EDITOR_ELEMENTS = 'SET_EDITOR_ELEMENTS';
export const GET_EDITOR_TEMPLATE = 'GET_EDITOR_TEMPLATE';
export const GET_FONTS_LIST = 'GET_FONTS_LIST';
export const SAVE_EDITOR_TEMPLATE = 'SAVE_EDITOR_TEMPLATE';
export const DISCARD_EDITOR_TEMPLATE = 'DISCARD_EDITOR_TEMPLATE';
export const PUBLISH_EDITOR_TEMPLATE = 'PUBLISH_EDITOR_TEMPLATE';
export const CREATIVE_EDITOR_RESET = 'CREATIVE_EDITOR_RESET';
export const SET_SAVE_STATUS = 'SET_SAVE_STATUS';
export const SET_GENERATION_STATUS = 'SET_GENERATION_STATUS';
export const SET_BATCH_GENERATION_PROGRESS = 'SET_BATCH_GENERATION_PROGRESS';
export const BATCH_IS_EDITING_COMPONENT = 'BATCH_IS_EDITING_COMPONENT';

export const EDITOR_TEMPLATE_ACTION_REQUEST = 'EDITOR_TEMPLATE_ACTION_REQUEST';
export const EDITOR_TEMPLATE_ACTION_FINISH = 'EDITOR_TEMPLATE_ACTION_FINISH';

// todo remove
export const TOGGLE_ALLOW_VALIDATE = 'TOGGLE_ALLOW_VALIDATE';

export const EDITOR_SET_RTE_FONT = 'EDITOR_SET_RTE_FONT';

export const EDITOR_SET_SHOW_ASSETS_SELECTION = 'EDITOR_SET_SHOW_ASSETS_SELECTION';
export const EDITOR_SET_TIMELINE_ZOOM = 'EDITOR_SET_TIMELINE_ZOOM';
export const MARK_AS_UNSAVED = 'MARK_AS_UNSAVED';

// end creativeEditor

// collections
export const COLLECTIONS_RESET = 'COLLECTIONS_RESET';
export const COLLECTIONS_RESET_OWNERSHIP = 'COLLECTIONS_RESET_OWNERSHIP';
export const COLLECTIONS_START_FETCH = 'COLLECTIONS_START_FETCH';
export const COLLECTIONS_FAIL_FETCH = 'COLLECTIONS_FAIL_FETCH';
export const COLLECTIONS_SUCCESS_FETCH = 'COLLECTIONS_SUCCESS_FETCH';
export const COLLECTIONS_TOGGLE_ITEM = 'COLLECTIONS_TOGGLE_ITEM';
export const COLLECTIONS_TOGGLE_ALL = 'COLLECTIONS_TOGGLE_ALL';

export const COLLECTION_RESET = 'COLLECTION_RESET';
export const COLLECTION_START_FETCH = 'COLLECTION_START_FETCH';
export const COLLECTION_SUCCESS_FETCH = 'COLLECTION_SUCCESS_FETCH';
export const COLLECTION_FAILURE_FETCH = 'COLLECTION_FAILURE_FETCH';
export const COLLECTION_SUCCESS_ADD = 'COLLECTION_SUCCESS_ADD';
export const COLLECTION_SUCCESS_REMOVE = 'COLLECTION_SUCCESS_REMOVE';
export const COLLECTION_RENAME_IN_PROGRESS = 'COLLECTION_RENAME_IN_PROGRESS';
export const COLLECTION_RENAME_SUCCESS = 'COLLECTION_RENAME_SUCCESS';
export const COLLECTION_RENAME_FAILURE = 'COLLECTION_RENAME_FAILURE';
export const COLLECTION_DELETE_IN_PROGRESS = 'COLLECTION_DELETE_IN_PROGRESS';
export const COLLECTION_DELETE_SUCCESS = 'COLLECTION_DELETE_SUCCESS';
export const COLLECTION_DELETE_FAILURE = 'COLLECTION_DELETE_FAILURE';
export const COLLECTION_ADD_CREATIVE_IN_PROGRESS = 'COLLECTION_ADD_CREATIVE_IN_PROGRESS';
export const COLLECTION_ADD_CREATIVE_SUCCESS = 'COLLECTION_ADD_CREATIVE_SUCCESS';
export const COLLECTION_ADD_CREATIVE_FAILURE = 'COLLECTION_ADD_CREATIVE_FAILURE';
export const COLLECTION_ADD_NEW_IN_PROGRESS = 'COLLECTION_ADD_NEW_IN_PROGRESS';
export const COLLECTION_ADD_NEW_SUCCESS = 'COLLECTION_ADD_NEW_SUCCESS';
export const COLLECTION_ADD_NEW_FAILURE = 'COLLECTION_ADD_NEW_FAILURE';
export const COLLECTION_REMOVE_FROM_CREATIVE_IN_PROGRESS = 'COLLECTION_REMOVE_FROM_CREATIVE_IN_PROGRESS';
export const COLLECTION_REMOVE_FROM_CREATIVE_SUCCESS = 'COLLECTION_REMOVE_FROM_CREATIVE_SUCCESS';
export const COLLECTION_REMOVE_FROM_CREATIVE_FAILURE = 'COLLECTION_REMOVE_FROM_CREATIVE_FAILURE';
export const COLLECTION_EDIT_IN_PROGRESS = 'COLLECTION_EDIT_IN_PROGRESS';
export const COLLECTION_EDIT_SUCCESS = 'COLLECTION_EDIT_SUCCESS';
export const COLLECTION_EDIT_FAILURE = 'COLLECTION_EDIT_FAILURE';
export const COLLECTIONS_CHANGE_SOME = 'COLLECTIONS_CHANGE_SOME';
// end collections

// presets
export const PRESETS_RESET = 'PRESETS_RESET';
export const PRESETS_START_FETCH = 'PRESETS_START_FETCH';
export const PRESETS_SUCCESS_FETCH = 'PRESETS_SUCCESS_FETCH';
export const PRESETS_FAIL_FETCH = 'PRESETS_FAIL_FETCH';
export const PRESETS_SET_ITEMS = 'PRESETS_SET_ITEMS';

export const PRESETS_RENAME_REQUEST = 'PRESETS_RENAME_REQUEST';
export const PRESETS_RENAME_SUCCESS = 'PRESETS_RENAME_SUCCESS';
export const PRESETS_RENAME_FAILURE = 'PRESETS_RENAME_FAILURE';
// end presets

// platform
export const UPDATE_PLATFORM_OPTIONS = 'UPDATE_PLATFORM_OPTIONS';
export const TOGGLE_ON_DEMAND_PAGE_STATE = 'TOGGLE_ON_DEMAND_PAGE_STATE';
export const TOGGLE_APP_INITIALIZING = 'TOGGLE_APP_INITIALIZING';
// end platform
